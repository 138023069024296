<template>
	<div style="padding-top: 20px">

		<div class="text-center" style="font-size: 22px; font-weight: bold; color: #444;">
			Welcome {{ name }}
		</div>
		<div style="height: 20px;"></div>
		<div class="container-fluid">
			<div class="row">

				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-info">
						<div class="card-body">
							<div class="text-value">{{ bages.xray_reports }}</div>
							<div>Number of X-Ray case today</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-primary">
						<div class="card-body">
							<div class="text-value">{{ bages.ecg_reports }}</div>
							<div>Number of ECG case today</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-warning">
						<div class="card-body">
							<div class="text-value">{{ bages.pending_xray_reports }}</div>
							<div>Pending X-Ray Reports</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-lg-3">
					<div class="card text-white bg-danger">
						<div class="card-body">
							<div class="text-value">{{ bages.pending_ecg_reports }}</div>
							<div>Pending ECG Reports</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		
		<div class="container-fluid" style="margin-top: 15px;">
			<div class="row">
				<div class="col-sm-12">
					<canvas id="myChart" style="width:100%; max-height: 460px;"></canvas>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
export default {
	data() {
		return {
			bages: {},
			name: '',
		}
	},
	methods: {
		async enter() {
			let user = JSON.parse(window.localStorage.admin_access_token);
			// this.isSuper = user.is_super == 'y';
			this.name = user.name;



			this.bages = await this.get("admin/dashboard-bages");

		},
	},
	async mounted(){
		let getDate = (adjust) => {
			let time = new Date();
			time.setTime(time.getTime() + adjust * 24 * 60 * 60 * 1000);

			let day = this.format(time, "D");

			let date = this.format(time, "YYYY-MM-DD");
			date = new Date(date);
			date.setTime(date.getTime() - 5.5 * 60 * 60 * 1000);
			let start = date.toISOString();

			time = new Date();
			time.setTime(time.getTime() + (adjust + 1) * 24 * 60 * 60 * 1000);
			date = this.format(time, "YYYY-MM-DD");
			date = new Date(date);
			date.setTime(date.getTime() - 5.5 * 60 * 60 * 1000);
			let end = date.toISOString();

			return {
				start,
				end,
				day
			}
		}
		let dates = [];
		for (let i = 0; i < 30; i++) {
			dates.unshift(getDate(i * -1));
		}
		console.log(dates);

		
		let data = await this.post('admin/load-graph-data', {
			dates,
		});

		var xValues = [];//"Italy", "France", "Spain", "USA", "Argentina", "sdlkj", "ksdj"
		var xrayData = [];
		var ecgData = [];
		var amountData = [];
		for (let date of data) {
			xValues.push(date.day);
			xrayData.push(date.xray);
			ecgData.push(date.ecg);
			amountData.push(date.amount);
		}



		new Chart("myChart", {
		type: "line",
			data: {
				labels: xValues,
				datasets: [{ 
					data: ecgData,
					borderColor: "red",
					fill: false
				}, { 
					data: amountData,
					borderColor: "green",
					fill: true
				}, { 
					data: xrayData,
					borderColor: "blue",
					fill: false
				}]
			},
			options: {
				legend: {display: false},
				title: {
					display: true,
					text: "Reports"
				}
			}
		});
	}
}
</script>

<style scoped>
.card-body {
	flex: 1 1 auto;
	padding: 1.25rem;
}

.text-value {
	font-size: 1.3125rem;
	font-weight: 600;
}
</style>